import React from "react";
import "./placeholder.scss";
import logomark from "../../assets/DataHyve-Logos/03_Logomark/Logomark-blue.svg";

const Placeholder = (className: any) => {
  return (
    <div className={className.className + " placeholder"}>
      <div className="left">
        <div className="top">
          <h1 className="title">Coming soon</h1>
          <p className="subtext">We are working on our website</p>
        </div>
        <div className="bottom">
          <div className="contact-container">
            <h3 className="subtitle">Contact</h3>
            <ul className="list">
              <li className="list-item">
                <a href="tel:+310850090358"> +31 (0) 85 009 03 58 </a>
              </li>
              <li className="list-item">
                <a href="mailto:info@datahyve.com">info@datahyve.com</a>
              </li>
            </ul>
          </div>
          <div className="adress-container">
            <h3 className="subtitle">address</h3>
            <ul className="list">
              <li className="list-item">
                <a
                  href="https://www.google.nl/maps/place/Schaverijstraat+11,+3534+AS+Utrecht/@52.1063023,5.0778861,816m/data=!3m2!1e3!4b1!4m6!3m5!1s0x47c665b9ddeb7231:0x380098166cbda701!8m2!3d52.106299!4d5.080461!16s%2Fg%2F11t3m3dxwz?entry=ttu&g_ep=EgoyMDI0MDkyOS4wIKXMDSoASAFQAw%3D%3D"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Schaverijstraat 11, 3534 AS Utrecht{" "}
                </a>
              </li>
              <li className="list-item">
                <a
                  href="https://www.google.nl/maps/place/Overschiestraat+55,+1062+HN+Amsterdam/@52.3478144,4.8351725,811m/data=!3m2!1e3!4b1!4m10!1m2!2m1!1soverschietstraat+55!3m6!1s0x47c5e21fdeff75fd:0x6138dfe36fcb1ff7!8m2!3d52.3478112!4d4.8400434!15sChJvdmVyc2NoaWVzdHJhYXQgNTWSARFjb21wb3VuZF9idWlsZGluZ-ABAA!16s%2Fg%2F11bw3xf125?entry=ttu&g_ep=EgoyMDI0MDkyOS4wIKXMDSoASAFQAw%3D%3D"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Overschietstraat 55 1062 HN Amsterdam
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="right">
        <img className="logomark" src={logomark} alt="logomark" />
      </div>
    </div>
  );
};

export default Placeholder;
