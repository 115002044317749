import React from "react";
import "./App.scss";
import Header from "./components/header";
import Placeholder from "./components/placeholder";

function App() {
  return (
    <div className="App">
      <Header className="header-component" />
      <Placeholder className="placeholder-component" />
    </div>
  );
}

export default App;
