import React from "react";
import logo from "../../assets/DataHyve-Logos/01_Logo_regular/Light/Logo-light.svg";
import "./header.scss";

const Header = (className: any) => {
  return (
    <header className={className.className + " header"}>
      <img className="logo" alt="datahyve-logo" src={logo} />
    </header>
  );
};

export default Header;
